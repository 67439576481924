.skill-cloud{
    list-style: none;
    overflow:hidden;
    display:block;
}
.skill-cloud .skill-cloud-item{
    padding:5px 10px;
    margin:5px;
    display:inline-block;
    color: #fff;
    background:#11ABB0;
    border-radius:3px;
    cursor:default;
}
.highlighted-text{
    color:#11ABB0 !important;
}
.banner-desc{
    color:#fff !important;
}
header{
    background-attachment:fixed;
}